.contact{
    color: rgb(43, 42, 42);
}
.contact h3{
    margin-bottom: 100px;
}
.contact .form{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}
.contact .form .row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
@media screen and (max-width:700px) {
    .contact .form .row{
        flex-direction: column;
    }
}
.contact .form .form-group{
    display: flex;
    flex-direction: column;
}
.contact .form .form-group label{
    font-size: 18px;
    font-weight: bold;
}
.contact .form .row  .form-group input{
    border: 1px solid rgb(146, 146, 146);
    /* box-shadow: 0 2px 3px 2px rgba(122, 120, 120, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19); */
    border-radius: 5px;
    width: 100%;
    margin: auto;
    margin-bottom: 70px;
    height: 40px;
}
@media screen and (max-width:700px) {
    .contact .form .form-group input{
        margin-bottom: 20px;
    }
}

.contact .form .form-group textarea{
    border: 1px solid rgb(146, 146, 146);
    /* box-shadow: 0 2px 3px 2px rgba(122, 120, 120, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19); */
    border-radius: 5px;
    width: 60%;
    margin: auto;
    margin-bottom: 70px;
    height: 300px;
}
@media screen and (max-width:700px) {
    .contact .form .form-group textarea{
        width: 100%;
    }
}

.contact .form .submit-btn{
    border: 1px solid grey;
    border-radius: 5px;
    padding: 15px;
    width: 20%;
    margin: auto;
    margin-bottom: 100px;
    font-size: 20px;
    font-weight: bolder;
    color: rgb(43, 42, 42);
}
.contact .form .submit-btn:hover{
    background-color: grey;
    color: white;
}

