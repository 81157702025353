.footer{
    padding-top: 5px;
    background:  linear-gradient(to top, rgb(136, 136, 135), rgb(71, 71, 71));
    height: 300px;
    position: relative;
    text-align: center;
    border-radius: 30px 30px 0px 0px;
}
.footer h3{
    color: white;
}
.footer .footer-text{
    padding: 30px;
    font-size: 17px;
    color: white;
}
.social-icons{
    display: flex;
    justify-content: space-between;
    width: 20%;
    margin: auto;
    margin-left: 40%;
    color: white;
    position: absolute;
    bottom: 80px;
}
.copyright{
    color: white;
    position: absolute;
    bottom: 25px;
    margin: auto;
    margin-left: 40%;
}
@media screen and (max-width:700px) {
    .footer{
        width: auto;
    }
    .copyright{
        margin-left: 12%;
    }
}