.template{
    margin: 20px;
    width: 325px;
    height: 325px;
    box-shadow: 0 4px 5px 3px rgba(122, 120, 120, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}
@media screen and (max-width:700px) {
    .template{
        margin: 25px;
    }
}
.template .template-image img{
    opacity: 1;
    transition: .5s ease;
    backface-visibility: hidden;
    object-fit: cover;
    max-width: 100%;
    height: 334px;

}
.template .template-text{
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 30%;
    text-align: center;
}
.template:hover .template-image {
    opacity: 0.3;
}
  
.template:hover .template-text {
    opacity: 1;
}

/*styling template text*/
.template .title{
    font-size: 20px;
    font-weight: bold;
    color: rgb(63, 62, 62);
    margin-bottom: 10px;
}
.template .description{
    font-size: 18px;
    color: rgb(63, 62, 62);
    margin-bottom: 20px;
}

.template .button {
    height: 30px;
    width: 40%;
    margin: auto;
    color: rgb(63, 62, 62);
    border-radius: 5px;
    border-style: solid;
    border-color: rgb(160, 66, 66);
    box-shadow: 0 4px 5px 3px rgba(122, 120, 120, 0.2), 0 6px 10px 0 rgb(150, 69, 69);
    border-width: 2px;
}

