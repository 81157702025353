h3{
    font-size: 30px;
    font-weight: bold;
    color: rgb(63, 62, 62);
}
p{
    font-size: 18px;
    color: rgb(63, 62, 62);
}
a{
    text-decoration: none !important;
    color: black;
}
@media screen and (max-width:700px) {
    h3{
        text-align: center;
        font-size: 30px;
        padding: 35px;
    }
    p{
        text-align: center;
    }
}