body {
  margin: 0;
  text-align: center;
}


h3{
    font-size: 30px;
    font-weight: bold;
    color: rgb(63, 62, 62);
}
p{
    font-size: 18px;
    color: rgb(63, 62, 62);
}
a{
    text-decoration: none !important;
    color: black;
}
@media screen and (max-width:700px) {
    h3{
        text-align: center;
        font-size: 30px;
        padding: 35px;
    }
    p{
        text-align: center;
    }
}
.footer{
    padding-top: 5px;
    background:  linear-gradient(to top, rgb(136, 136, 135), rgb(71, 71, 71));
    height: 300px;
    position: relative;
    text-align: center;
    border-radius: 30px 30px 0px 0px;
}
.footer h3{
    color: white;
}
.footer .footer-text{
    padding: 30px;
    font-size: 17px;
    color: white;
}
.social-icons{
    display: flex;
    justify-content: space-between;
    width: 20%;
    margin: auto;
    margin-left: 40%;
    color: white;
    position: absolute;
    bottom: 80px;
}
.copyright{
    color: white;
    position: absolute;
    bottom: 25px;
    margin: auto;
    margin-left: 40%;
}
@media screen and (max-width:700px) {
    .footer{
        width: auto;
    }
    .copyright{
        margin-left: 12%;
    }
}
.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 80px;
    margin-bottom: 180px;
}
.header-title{
    color: rgb(63, 62, 62);
    padding: 20px;
    font-size: 35px;
    font-weight: bolder;
}
.header-right .contact-us{
    margin: 20px;
    padding: 20px;
    border: 2px solid grey;
    border-radius: 5px;
    box-shadow: 0 4px 5px 3px rgba(122, 120, 120, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}
.header-right .contact-us:hover{
    background-color: grey;
    color: white;
}

@media screen and (max-width:700px) {
    .header{
        margin-bottom: 100px;
    }
    .header-right .contact-us{
        margin: 20px;
        padding: 10px;
    }
}

.home{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home .avatar{
    margin-top: 50px;
    -webkit-animation: rotation 30s infinite linear;
            animation: rotation 30s infinite linear;
    margin-bottom: 50px;
}
/* rotation frames for avatar*/
@-webkit-keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
}
@keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
}

.home .avatar img{
    max-width: 200px;
    max-height: 200px;
}

.home .devices-avatar img{
    max-width: 1080px;
}
@media screen and (max-width: 700px) {
    .home .devices-avatar img{
        display: none;
        width: 0px;
        margin-left:20px;
    }
}


.home .about{
    text-align: center;
    background:  linear-gradient(to top, rgb(136, 136, 135), rgb(71, 71, 71));
    height: 300px;
    border-radius: 30px;
    margin: 30px;
    margin-top: 10px;
}
.home .about h3{
    color: white;
    font-size: 35px;
}

.home .about p{
    color: white;
    font-size: 22px;
    padding-left: 200px;
    padding-right: 200px;
}
@media screen and (max-width: 700px) {
    .home .about{
        height: 400px;
        margin: 20px;
    }
    .home .about h3{
        font-size: 25px;
    }

    .home .about p{
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.home .services{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: 4px;
    width: 90%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 5px;
}
@media screen and (max-width:700px) {
    .home .services{
        margin: auto;
        flex-direction: column;
        align-items: center;
    }
}


.home .recent-work{
    margin: 80px;
    margin-top: 30px;
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
}
@media screen and (max-width:700px) {
    .home .recent-work{
        margin: 10px;
        grid-template-columns: auto;
    }
}
.home .collaborations{
    margin: 80px;
    margin-top: 30px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
}
@media screen and (max-width:700px) {
    .home .collaborations{
        grid-template-columns: auto ;
        margin-top: auto;
    }
}
.home .testimonials{
    /* scrolling action */
    display: grid;
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
    grid-template-rows: 1fr;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 40px;

}
/* Hide scrollbar for Chrome, Safari and Opera */
.home .testimonials::-webkit-scrollbar {
    display: none;
}
  
  /* Hide scrollbar for IE, Edge and Firefox */
.home .testimonials {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.service{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    height: 400px;
    width: 250px;

    box-shadow: 0 4px 5px 3px rgba(122, 120, 120, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;

}
.service .icon-container{
    margin: auto;
    display: flex;
    justify-content: space-around;
    color: white;
    background-color: grey;
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.service .icon-container .icon{
    display: flex;
    justify-content: center;
    align-items: center;

}
.service .title{
    margin: auto;
    font-size: 30px;
    font-weight: bold;
    color: rgb(63, 62, 62);
}
.service .description{
    margin: auto;
    padding: 10px;
    font-size: 18px;
    color: rgb(63, 62, 62);
    
}
@media screen and (max-width:700px) {
    .service{
        margin: 15px;
    }
}
.template{
    margin: 20px;
    width: 325px;
    height: 325px;
    box-shadow: 0 4px 5px 3px rgba(122, 120, 120, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}
@media screen and (max-width:700px) {
    .template{
        margin: 25px;
    }
}
.template .template-image img{
    opacity: 1;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    object-fit: cover;
    max-width: 100%;
    height: 334px;

}
.template .template-text{
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 30%;
    text-align: center;
}
.template:hover .template-image {
    opacity: 0.3;
}
  
.template:hover .template-text {
    opacity: 1;
}

/*styling template text*/
.template .title{
    font-size: 20px;
    font-weight: bold;
    color: rgb(63, 62, 62);
    margin-bottom: 10px;
}
.template .description{
    font-size: 18px;
    color: rgb(63, 62, 62);
    margin-bottom: 20px;
}

.template .button {
    height: 30px;
    width: 40%;
    margin: auto;
    color: rgb(63, 62, 62);
    border-radius: 5px;
    border-style: solid;
    border-color: rgb(160, 66, 66);
    box-shadow: 0 4px 5px 3px rgba(122, 120, 120, 0.2), 0 6px 10px 0 rgb(150, 69, 69);
    border-width: 2px;
}


.collaboration{
    font-size: 30px;
    font-weight: bold;
    padding: 30px;
}
@media screen and (max-width:700px) {
    .collaboration{
        padding: 20px;
    }
}
.testimonial{
    width: 400px;
    height: 150px;
    margin: 20px;
    padding: 20px;
    box-shadow: 0 4px 5px 3px rgba(122, 120, 120, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    position: relative;

    /* scrolling */
    grid-row: 1;
    grid-column: auto;
 
}
.testimonial .username{
    color:rgb(63, 62, 62);
    position: absolute;
    margin-left: 5px;
    bottom: 55px;
    font-size: 17px;
    font-weight: bolder;
}
.testimonial .rating{
    color: rgb(63, 62, 62);
    position: absolute;
    bottom: 15px;
}
.contact{
    color: rgb(43, 42, 42);
}
.contact h3{
    margin-bottom: 100px;
}
.contact .form{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}
.contact .form .row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
@media screen and (max-width:700px) {
    .contact .form .row{
        flex-direction: column;
    }
}
.contact .form .form-group{
    display: flex;
    flex-direction: column;
}
.contact .form .form-group label{
    font-size: 18px;
    font-weight: bold;
}
.contact .form .row  .form-group input{
    border: 1px solid rgb(146, 146, 146);
    /* box-shadow: 0 2px 3px 2px rgba(122, 120, 120, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19); */
    border-radius: 5px;
    width: 100%;
    margin: auto;
    margin-bottom: 70px;
    height: 40px;
}
@media screen and (max-width:700px) {
    .contact .form .form-group input{
        margin-bottom: 20px;
    }
}

.contact .form .form-group textarea{
    border: 1px solid rgb(146, 146, 146);
    /* box-shadow: 0 2px 3px 2px rgba(122, 120, 120, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19); */
    border-radius: 5px;
    width: 60%;
    margin: auto;
    margin-bottom: 70px;
    height: 300px;
}
@media screen and (max-width:700px) {
    .contact .form .form-group textarea{
        width: 100%;
    }
}

.contact .form .submit-btn{
    border: 1px solid grey;
    border-radius: 5px;
    padding: 15px;
    width: 20%;
    margin: auto;
    margin-bottom: 100px;
    font-size: 20px;
    font-weight: bolder;
    color: rgb(43, 42, 42);
}
.contact .form .submit-btn:hover{
    background-color: grey;
    color: white;
}


