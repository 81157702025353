.testimonial{
    width: 400px;
    height: 150px;
    margin: 20px;
    padding: 20px;
    box-shadow: 0 4px 5px 3px rgba(122, 120, 120, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    position: relative;

    /* scrolling */
    grid-row: 1;
    grid-column: auto;
 
}
.testimonial .username{
    color:rgb(63, 62, 62);
    position: absolute;
    margin-left: 5px;
    bottom: 55px;
    font-size: 17px;
    font-weight: bolder;
}
.testimonial .rating{
    color: rgb(63, 62, 62);
    position: absolute;
    bottom: 15px;
}