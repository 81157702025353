.service{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    height: 400px;
    width: 250px;

    box-shadow: 0 4px 5px 3px rgba(122, 120, 120, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;

}
.service .icon-container{
    margin: auto;
    display: flex;
    justify-content: space-around;
    color: white;
    background-color: grey;
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.service .icon-container .icon{
    display: flex;
    justify-content: center;
    align-items: center;

}
.service .title{
    margin: auto;
    font-size: 30px;
    font-weight: bold;
    color: rgb(63, 62, 62);
}
.service .description{
    margin: auto;
    padding: 10px;
    font-size: 18px;
    color: rgb(63, 62, 62);
    
}
@media screen and (max-width:700px) {
    .service{
        margin: 15px;
    }
}