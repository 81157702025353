.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 80px;
    margin-bottom: 180px;
}
.header-title{
    color: rgb(63, 62, 62);
    padding: 20px;
    font-size: 35px;
    font-weight: bolder;
}
.header-right .contact-us{
    margin: 20px;
    padding: 20px;
    border: 2px solid grey;
    border-radius: 5px;
    box-shadow: 0 4px 5px 3px rgba(122, 120, 120, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}
.header-right .contact-us:hover{
    background-color: grey;
    color: white;
}

@media screen and (max-width:700px) {
    .header{
        margin-bottom: 100px;
    }
    .header-right .contact-us{
        margin: 20px;
        padding: 10px;
    }
}
