.home{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home .avatar{
    margin-top: 50px;
    animation: rotation 30s infinite linear;
    margin-bottom: 50px;
}
/* rotation frames for avatar*/
@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}

.home .avatar img{
    max-width: 200px;
    max-height: 200px;
}

.home .devices-avatar img{
    max-width: 1080px;
}
@media screen and (max-width: 700px) {
    .home .devices-avatar img{
        display: none;
        width: 0px;
        margin-left:20px;
    }
}


.home .about{
    text-align: center;
    background:  linear-gradient(to top, rgb(136, 136, 135), rgb(71, 71, 71));
    height: 300px;
    border-radius: 30px;
    margin: 30px;
    margin-top: 10px;
}
.home .about h3{
    color: white;
    font-size: 35px;
}

.home .about p{
    color: white;
    font-size: 22px;
    padding-left: 200px;
    padding-right: 200px;
}
@media screen and (max-width: 700px) {
    .home .about{
        height: 400px;
        margin: 20px;
    }
    .home .about h3{
        font-size: 25px;
    }

    .home .about p{
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.home .services{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: 4px;
    width: 90%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 5px;
}
@media screen and (max-width:700px) {
    .home .services{
        margin: auto;
        flex-direction: column;
        align-items: center;
    }
}


.home .recent-work{
    margin: 80px;
    margin-top: 30px;
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
}
@media screen and (max-width:700px) {
    .home .recent-work{
        margin: 10px;
        grid-template-columns: auto;
    }
}
.home .collaborations{
    margin: 80px;
    margin-top: 30px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
}
@media screen and (max-width:700px) {
    .home .collaborations{
        grid-template-columns: auto ;
        margin-top: auto;
    }
}
.home .testimonials{
    /* scrolling action */
    display: grid;
    grid-auto-columns: max-content;
    grid-template-rows: 1fr;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 40px;

}
/* Hide scrollbar for Chrome, Safari and Opera */
.home .testimonials::-webkit-scrollbar {
    display: none;
}
  
  /* Hide scrollbar for IE, Edge and Firefox */
.home .testimonials {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}